<template>
  <VaNavbar class="app-layout-navbar py-2 px-0">
    <template #left>
      <div class="left">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon
            color="primary"
            :name="isSidebarMinimized ? 'menu' : 'close'"
            size="24px"
            style="margin-top: 3px"
            @click="isSidebarMinimized = !isSidebarMinimized"
          />
        </Transition>
        <RouterLink to="/" aria-label="Visit home page">
          <img :src=data>
        </RouterLink>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import { useColors } from 'vuestic-ui'
import { ref, onMounted, onBeforeUpdate, watch } from 'vue'

import axios from 'axios'

// Compongo endpoint in base al device che mi viene passato
const endpoint = 'https://api.klepsydris.com/v1/portal/preferences'

// Risposta di Axios per riferimento
let axiosResponse: Object

const details = ref()

const { applyPreset, currentPresetName } = useColors()

const data = ref("")

const theme = ref('')

defineProps({
  isMobile: { type: Boolean, default: false },
})


// Aggiungo un wathcer per vedere quando cambio il tema
watch(currentPresetName, (newTheme) => {

  if(currentPresetName.value == 'dark'){

    data.value = '/KLEPSYDRIS 32px bianco.png'
  }
  else if(currentPresetName.value == 'light'){

    data.value = '/KLEPSYDRIS 32px.png'

  }
})

// All'avvio setto il logo in base al tema iniziale
onMounted(async () => {


  await getPreferences();

  // Applico theme
  applyPreset(axiosResponse.theme)



  
  if(currentPresetName.value == 'dark'){

    theme.value = currentPresetName.value

    data.value = '/KLEPSYDRIS 32px bianco.png'

  }

  else if(currentPresetName.value == 'light'){

    theme.value = currentPresetName.value

     data.value = '/KLEPSYDRIS 32px.png'

  }


})

async function getPreferences() {
  // Create an Axios instance
  const axiosInstance = axios.create({
    withCredentials: true, // Ensure credentials are sent with every request
  })

  const catFactsResponse = await // Now, when you use axiosInstance, it will automatically send the cookie
  axiosInstance
    .get('https://api.klepsydris.com/v1/portal/preferences')
    .then((response) => {
      axiosResponse = response.data

  
    })
    .catch((error) => {
      console.error('Error fetching data:', error)

    })
}


const GlobalStore = useGlobalStore()

const { isSidebarMinimized } = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
</style>
